<template lang="pug">
	section#eventos
		.wrapper
			ul
				li(v-for="evento, index in eventos")
					router-link(:to="{'name': 'evento', params:{'slug': evento.slug} }", v-if="!evento.online")
						div.img
							img(:src="evento.listagem_thumb ? evento.listagem_thumb : require('@images/temp/imgeventos.png')", :style="evento.thumb_listagem && evento.thumb_listagem.size.width > evento.thumb_listagem.size.height ? cropHeigth : cropWidth")
						div.conteudo
							strong(v-if="evento.destaque") destaque
							h3 {{evento.titulo}}
							span {{!evento.online ? `${evento.local.cidade_nome} -` : ``}} {{evento.inicio}} a {{evento.termino}}
							p {{evento.descricao}}
							button.inscreva-se Inscreva-se

					a(:href="evento.link_online", target="_blank", v-else)
						div.img
							img(:src="evento.listagem_thumb ? evento.listagem_thumb : require('@images/temp/imgeventos.png')", :style="evento.thumb_listagem && evento.thumb_listagem.size.width > evento.thumb_listagem.size.height ? cropHeigth : cropWidth")
						div.conteudo
							strong(v-if="evento.destaque") destaque
							h3 {{evento.titulo}}
							span {{!evento.online ? `${evento.local.cidade_nome} -` : ``}} {{evento.inicio}} a {{evento.termino}}
							p {{evento.descricao}}
							button.inscreva-se Inscreva-se
			router-link(:to="{name: 'eventos'}").vertodos Ver Todos os Eventos
</template>

<script>
export default {
	props: {
		eventos: {
			type: Array,
		}
	},
	data() {
		return {
			cropHeigth: {
				maxHeight: "100%",
				width: "auto"
			},
			cropWidth: {
				maxWidth: "100%",
				height: "auto"
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Eventos.styl"></style>