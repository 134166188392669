<template lang="pug">
	section#bannerHome
		div.galeria
			button.seta.left(@click="toggleGaleria(bannerAtivo - 1)", v-if="eventos.length > 1")
				img(:src="require('@images/icones/seta_branca.svg')")
			button.seta.right(@click="toggleGaleria(bannerAtivo + 1)", v-if="eventos.length > 1")
				img(:src="require('@images/icones/seta_branca.svg')")
			div.slide(v-for="evento, index in eventos" :class="{'ativo' : index == bannerAtivo}")
				div.img
					picture
						source(media="(min-width: 600px)" :srcset="evento.home_destaque ? evento.home_destaque : require('@images/temp/imghome.png')")
						source(media="(max-width: 599px)" :srcset="evento.home_destaque_mobile ? evento.home_destaque_mobile : require('@images/temp/imghome.png')")
						img(:src="evento.home_destaque ? evento.home_destaque : require('@images/temp/imghome.png')")
					
				.wrapper
					h2 {{evento.titulo}}
					p {{!evento.online ? `${evento.local.cidade_nome} -` : ``}} {{evento.inicio}} a {{evento.termino}}
					router-link(:to="{'name': 'evento', params:{'slug': evento.slug} }", v-if="!evento.online") Inscreva-se
					a(:href="evento.link_online", target="_blank", v-else) Inscreva-se
</template>

<script>
export default {
	props: {
		eventos: {
			type: Array,
		}
	},
	data() {
		return {
			bannerAtivo: 0
		}
	},
	methods: {
		toggleGaleria(index) {
			if(index > this.$props.eventos.length - 1)
				this.bannerAtivo = 0
			else if(index < 0)
				this.bannerAtivo = this.$props.eventos.length - 1
			else
				this.bannerAtivo = index
		}
	},
}
</script>

<style lang="stylus" scoped src="./BannerEventos.styl">
</style>