<template lang="pug">
	article#home
		BannerEvento(:eventos="eventos", v-if="eventos")
		Eventos(:eventos="eventos", v-if="eventos")
</template>

<script>
import Eventos from '@sections/home/eventos/Eventos.vue'
import BannerEvento from '@sections/home/bannerEventos/BannerEventos.vue'
export default {
	components: {
		BannerEvento, 
		Eventos
	},
	created() {
		this.loadEventos()
	},
	data() {
		return {
			eventos: null
		}
	},
	methods: {
		loadEventos() {
			this.$axios
				.get(`api/home/eventos`)
				.then(response => {
					this.eventos = response.data.registros
				})
		}
	},
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>